import { defineNuxtPlugin } from '#imports'
import useConfigEnv from '../composables/useConfigEnv'
import useAuthorizeHeader from '../composables/useAuthorizeHeader'

export default defineNuxtPlugin(() => {
  const configEnv = useConfigEnv()
  const { updateAuthorizeHeader } = useAuthorizeHeader()

  const apiV5 = $fetch.create({
    baseURL: configEnv.NUXT_ENV_BASE_API_AUTH as string,
    async onRequest({ options }) {
      options.credentials = 'include'
      const headers = (options.headers ||= {})
      const activeRefreshToken = true
      await updateAuthorizeHeader(headers, activeRefreshToken)
    },
    async onResponseError({ response }) {
      console.error(response)
    }
  })

  return {
    provide: {
      apiV5
    }
  }
})
