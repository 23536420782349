import { useCookie, type CookieOptions } from '#app'
import type { ABTestingVariation } from '@/services/ABTesting/types/ABTesting.interface'
import { didVariationRangeChanged } from '@/services/ABTesting/helpers/ABTesting'

export default () => {
  const ABTestingRemoveCookie = (cookieName: string) => {
    const cookie = useCookie(cookieName)
    cookie.value = null
  }

  const ABTestingIsCookieValid = (
    cookieName: string,
    variations: ABTestingVariation[]
  ): boolean => {
    const cookie = useCookie<ABTestingVariation>(cookieName)
    if (!cookie.value) return false
    const variation = variations.find(
      ({ variationName }) => variationName === cookie.value.variationName
    )

    if (!variation) return false

    return !didVariationRangeChanged(variation, cookie.value)
  }

  const ABTestingSetCookie = (
    variation: ABTestingVariation,
    cookieName: string,
    options: CookieOptions<string> & { readonly?: false; domain: string }
  ) => {
    const expires = new Date()
    expires.setMonth(expires.getMonth() + 1) //! Arbitrary
    const cookie = useCookie(cookieName, { expires, ...options })
    cookie.value = JSON.stringify(variation)
  }

  return {
    ABTestingRemoveCookie,
    ABTestingIsCookieValid,
    ABTestingSetCookie
  }
}
