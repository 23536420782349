import { useRuntimeConfig } from '#imports'

interface ConfigEnv {
  NUXT_ENV_BASE_API_AUTH: string
  NUXT_ENV_AUTH_URL_AUTH: string
}

export default (): ConfigEnv => {
  const config = useRuntimeConfig()

  const { NUXT_ENV_BASE_API_AUTH, NUXT_ENV_AUTH_URL_AUTH } =
    config.public as Record<string, string>

  return {
    NUXT_ENV_BASE_API_AUTH,
    NUXT_ENV_AUTH_URL_AUTH
  }
}
