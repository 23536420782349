import type {
  ABTestingEnvVar,
  ABTestingVariation
} from '@/services/ABTesting/types/ABTesting.interface'

export const ABTestingSelectVariation = (
  variations: ABTestingVariation[]
): ABTestingVariation => {
  if (
    variations[0].range[0] !== 0 ||
    variations[variations.length - 1].range[1] !== 100
  ) {
    throw new Error(
      'AB testing variations must cover the full range from 0 to 100'
    )
  }
  const dice100 = Math.ceil(Math.random() * 100)

  const selectedVariation = variations.find(({ range }) => {
    return range[0] <= dice100 && range[1] >= dice100
  })

  if (!selectedVariation) {
    throw new Error(
      `AB testing variation not fond for dice ${dice100}. Check if AB test variations covers the full range from 0 to 100 ${variations}`
    )
  }

  return selectedVariation!
}

export const ABTestingFormatEnvVar = (
  rawVariation: ABTestingEnvVar
): ABTestingVariation[] => {
  return Object.entries(rawVariation).map(([variationName, range]) => ({
    variationName,
    range
  }))
}

export const didVariationRangeChanged = (
  variation: ABTestingVariation,
  cookie: ABTestingVariation
) => {
  const [variationRange1, variationRange2] = variation.range
  const [cookieRange1, cookieRange2] = cookie.range

  return variationRange1 !== cookieRange1 || variationRange2 !== cookieRange2
}
