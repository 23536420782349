import { defineNuxtPlugin } from '#imports'
import useConfigEnv from '../composables/useConfigEnv'
import useAuthorizeHeader from '../composables/useAuthorizeHeader'

export default defineNuxtPlugin(() => {
  const configEnv = useConfigEnv()
  const { updateAuthorizeHeader } = useAuthorizeHeader()

  const apiOAuth = $fetch.create({
    baseURL: configEnv.NUXT_ENV_AUTH_URL_AUTH as string,
    onRequest({ options }) {
      options.credentials = 'include'
      const headers = (options.headers ||= {})
      updateAuthorizeHeader(headers)
    },
    async onResponseError({ response }) {
      console.error(response)
    }
  })

  return {
    provide: {
      apiOAuth
    }
  }
})
