import useABTesting from '@/services/ABTesting/composables/useABTesting'
import useConfigEnv from '@/composables/useConfigEnv'
import { useLogger } from '@ha/components-v3'
import type {
  ABTestingEnvVar,
  ABTestingVariation
} from '@/services/ABTesting/types/ABTesting.interface'
import {
  ABTestingSelectVariation,
  ABTestingFormatEnvVar
} from '@/services/ABTesting/helpers/ABTesting'
import { computed } from 'vue'
import { useCookie } from '#app'

export default () => {
  const { NUXT_ENV_BASE_URL, AB_TESTING_REGISTRATION } =
    useConfigEnv() as Record<string, string>
  const { ABTestingSetCookie, ABTestingRemoveCookie, ABTestingIsCookieValid } =
    useABTesting()

  const { datadogRum } = useLogger()

  const cookieName = 'AB_TESTING_REGISTRATION'

  const ABTestingRegistrationVariation = computed(() => {
    return useCookie<ABTestingVariation>('AB_TESTING_REGISTRATION').value
      ?.variationName as 'A' | 'B' | 'C'
  })

  const ABTestingRegistrationIsVariantA = computed(() => {
    return ABTestingRegistrationVariation.value === 'A'
  })

  const ABTestingRegistrationIsVariantB = computed(() => {
    return ABTestingRegistrationVariation.value === 'B'
  })

  const ABTestingRegistrationIsVariantC = computed(() => {
    return ABTestingRegistrationVariation.value === 'C'
  })

  const ABTestingRegistrationInit = (): void => {
    if (!AB_TESTING_REGISTRATION) {
      ABTestingRemoveCookie(cookieName)
      return
    }

    const rawVariations: ABTestingEnvVar = JSON.parse(AB_TESTING_REGISTRATION)
    const variations = ABTestingFormatEnvVar(rawVariations)

    if (ABTestingIsCookieValid(cookieName, variations)) return

    const domain = NUXT_ENV_BASE_URL.replace('https://www', '')

    const selectedVariation = ABTestingSelectVariation(variations)

    datadogRum.addFeatureFlagEvaluation(
      'ab_test_register',
      selectedVariation.variationName
    )

    ABTestingSetCookie(selectedVariation, cookieName, {
      domain
    })
  }

  return {
    ABTestingRegistrationInit,
    ABTestingRegistrationVariation,
    ABTestingRegistrationIsVariantA,
    ABTestingRegistrationIsVariantB,
    ABTestingRegistrationIsVariantC
  }
}
