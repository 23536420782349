import { useCookie, useNuxtApp } from '#imports'
import updateRequestHeaders from '../helpers/updateRequestHeaders'
import { functions } from '@ha/helpers'

export default () => {
  const accessTokenName: string = 'tm5-HelloAsso'
  const refreshTokenName: string = 'rm5-HelloAsso'
  const refreshToken = useCookie(refreshTokenName)

  const { $apiOAuth } = useNuxtApp()
  const refreshAccessToken = async () => {
    const body: URLSearchParams = new URLSearchParams({
      grant_type: 'refresh_token',
      scope: '',
      refresh_token: refreshToken.value
    })
    await $apiOAuth('/token', {
      method: 'POST',
      body
    })
  }

  const checkIfNeedRefresh = () => {
    const accessToken = useCookie(accessTokenName)
    const accessTokenInformations = accessToken.value
      ? functions.getAccessTokenInformations(accessToken.value)
      : {}
    return accessTokenInformations.isExpired && refreshToken.value
  }

  const updateAuthorizeHeader = async (
    headers: HeadersInit,
    activeRefreshToken?: boolean
  ) => {
    /* OAuth api doesn't have to check if accessToken is expired, activeRefreshToken
    allows you to pass checkIfNeedRefresh and the refresh call */
    if (activeRefreshToken && checkIfNeedRefresh()) await refreshAccessToken()
    /* After refresh we need to retrieve accessTokenName again, otherwise the 
  old cookie will always be used, even if we use refreshCookie from nuxt */
    const freshAccessToken = useCookie(accessTokenName)
    if (freshAccessToken.value) {
      updateRequestHeaders(
        headers,
        'Authorization',
        `Bearer ${freshAccessToken.value}`
      )
    }
  }

  return { updateAuthorizeHeader }
}
