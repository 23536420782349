import user from './user.fr'
import association from './association.fr'

export default {
  registration: {
    user,
    association,
    notAssociationAdminNotice: {
      body: 'Vous souhaitez plutôt {strong} un projet ou une association',
      strong: 'soutenir',
      link: "C'est par ici"
    },
    alreadyHaveAnAccount: 'Vous avez déjà un compte ?',
    connection: 'Connexion',
    requiredFields: '*renseignement obligatoire'
  }
}
