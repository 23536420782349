export default (
  headers: HeadersInit,
  headerName: string,
  headerValue: string
) => {
  if (!headers || !headerName)
    throw new Error('Missing required param headers or headerName')

  if (Array.isArray(headers)) {
    headers.push([headerName, headerValue])
  } else if (headers instanceof Headers) {
    headers.set(headerName, headerValue)
  } else {
    headers[headerName] = headerValue
  }
}
