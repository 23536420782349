import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import useABTestingRegistration from '@/composables/useABTestingRegistration'
import useConfigEnv from '@/composables/useConfigEnv'
import { computed } from 'vue'

export default defineNuxtRouteMiddleware((to) => {
  const { AB_TESTING_REGISTRATION } = useConfigEnv()
  const {
    ABTestingRegistrationInit,
    ABTestingRegistrationIsVariantA,
    ABTestingRegistrationIsVariantB,
    ABTestingRegistrationIsVariantC
  } = useABTestingRegistration()

  ABTestingRegistrationInit()

  const hasQueries = computed(() => to.query && !!Object.keys(to.query).length)
  // ----------
  // Safe guards
  // ----------
  if (!AB_TESTING_REGISTRATION) {
    if (String(to.name).includes('nouvelle-inscription')) {
      return { name: 'inscription' }
    }

    return
  }

  if (hasQueries.value) {
    if (String(to.name).includes('nouvelle-inscription')) {
      return { name: 'inscription', query: to.query }
    }

    return
  }

  // ----------
  // AB Test redirection
  // ----------
  if (!ABTestingRegistrationIsVariantA.value && to.name === 'inscription') {
    return navigateTo({ name: 'nouvelle-inscription' })
  }

  // Prevent ending navigation on ".../nouvelle-inscription" page which is empty
  if (to.name === 'nouvelle-inscription') {
    if (ABTestingRegistrationIsVariantB.value) {
      return { name: 'nouvelle-inscription-utilisateur' }
    }

    if (ABTestingRegistrationIsVariantC.value) {
      return { name: 'nouvelle-inscription-association' }
    }

    return { name: 'inscription' }
  }
})
